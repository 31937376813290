import React, {useEffect, useState} from "react";
import { Api } from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import { UsersFunctions } from "./index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import CloseIcon from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";

import avatar from "PNH/assets/img/placeholder.jpg";
import Table from "../../../../../../../components/Table/Table";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const Form = ({values, setValues, classes,
                errors,
                handleChange,
                setFieldValue,
                handleSubmit,}) => {
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    Api.activities.admin.team_line_ups.index(values.activity_id, {with_competition_team_id: values.team_id}).then((response) => {
      if (response.ok) {
       setFieldValue('rows', response.data.rows)
      } else {
        window.setNotifications([
          { type: "alert", msg: "Coś poszło nie tak" },
        ]);
      }
    });
  }, []);

  const genders = {females: "female", males: 'male'}

  const filters = ['females', 'males'].includes(values.gender) ? { with_competition_team_id: values.team_id, with_gender: genders[values.gender]} : {with_competition_team_id: values.team_id}

  return (
      <GridContainer>

        <GridItem xs={12}>
          <GridContainer jusify="space-between">
            <GridItem xs>
              <AsyncSelector
                  apiMethod={(q) =>
                      Api.users.admin.index({
                        ...filters,
                        ...q,
                      })
                  }
                  labelKey="label"
                  label="Użytkownik"
                  name="user"
                  value={
                      values.user && {
                        id: values.user.id,
                        label: values.user.label,
                      }
                  }
                  onChange={(value, data) => {
                    setFieldValue("user", data);
                    setFieldValue("user_id", value);
                  }}
              />
            </GridItem>
            <GridItem xs>
              <Button
                  color="primary"
                  disabled={!values.user_id}
                  onClick={async (e) => {
                    await setFieldValue("action", "add");
                    handleSubmit(e);
                  }}
              >
                Dodaj
              </Button>
            </GridItem>

            <GridItem xs={12}>
              <GridContainer jusify="space-between">
                {!values.rows[0] && (
                    <GridItem xs={12}>
                      <Primary>
                        <h5>
                          <b>Brak Osób</b>
                        </h5>
                      </Primary>
                    </GridItem>
                )}

              </GridContainer>
            </GridItem>
              {values.rows.length > 0 && <GridItem xs={12}>
              <Table
                  striped
                  tableHead={[
                    "",
                    `Graczy: ${values.rows.length}/${values.activity.max_team_size}, min: ${values.activity.min_team_size}`,
                    "",
                  ]}
                  tableData={ values.rows.map((row, i) => [++i, row.player.label,  <Button
                      justIcon
                      simple
                      color="danger"
                      size="sm"
                      onClick={async (e) => {
                        await setFieldValue("action", "remove");
                        await setFieldValue("row_id", row.id);
                        handleSubmit(e);
                      }}
                  >
                    <CloseIcon />
                  </Button>])}
              />
            </GridItem>}
          </GridContainer>
        </GridItem>
      </GridContainer>
  );
}

export default withStyles(styles)(Form);
