import { create } from "apisauce";
import queryStringParser from "qs";

const apiConnection = create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/v2`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    'authorization': localStorage.getItem('token'),
  },
});

const loginApiConnection = create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});
apiConnection.addMonitor((response) => {
  if (response.status === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/auth'
  }
  if (response.status === 404) {
    window.location.href = '/404'
  }
});
export const serializeParams = (params = {}) =>
  queryStringParser.stringify(params);

export const Api = {
  competition_groups: {
    teams: {
      admin: {
        index_all: (params) => apiConnection.get(`admin/competition_teams`, params),
        create: (id, params) => apiConnection.post(`admin/competition_groups/${id}/competition_teams`, params),
        update: (id, competition_group_id, params) => apiConnection.patch(`admin/competition_groups/${competition_group_id}/competition_teams/${id}`, params),
        players: (competition_group_id, team_id, params) => apiConnection.get(`admin/competition_groups/${competition_group_id}/competition_teams/${team_id}/competition_teams_users`, params),
        add_player: ( competition_group_id, id, params) => apiConnection.post(`admin/competition_groups/${competition_group_id}/competition_teams/${id}/competition_teams_users`, params),
        update_player: ( competition_group_id, team_id, id, params) => apiConnection.patch(`admin/competition_groups/${competition_group_id}/competition_teams/${team_id}/competition_teams_users/${id}`, params),
        remove_player: (id, competition_group_id, team_id) =>  apiConnection.delete(`admin/competition_groups/${competition_group_id}/competition_teams/${team_id}/competition_teams_users/${id}`),
      }
    },
    admin: {
      index: (params) =>
          apiConnection.get(`admin/competition_groups?${serializeParams(params)}`),
      show: (id) =>
          apiConnection.get(`admin/competition_groups/${id}`),
      create: (params) => apiConnection.post(`admin/competition_groups`, params),
      update: (id, params) => apiConnection.patch(`admin/competition_groups/${id}`, params),
      delete: (id) => apiConnection.delete(`admin/competition_groups/${id}`),
      players: (id,params) =>
          apiConnection.get(`admin/competition_groups/${id}/competition_groups_users?${serializeParams(params)}`),
      update_player: (id, group_id, params) => apiConnection.patch(`admin/competition_groups/${group_id}/competition_groups_users/${id}`, params),
      add_player: (id, params) => apiConnection.post(`admin/competition_groups/${id}/competition_groups_users`, params),
      remove_player: (id, competition_group_id) => apiConnection.delete(`admin/competition_groups/${competition_group_id}/competition_groups_users/${id}`),
      admin_accept_invitation: (id, group_id, params) => apiConnection.post(`admin/competition_groups/${group_id}/competition_groups_users/${id}/admin_accept_invitation`, params),
    },

  },
  benefit_statuses:
  {
    admin: {
      show: (id, params) =>
        apiConnection.get(`admin/benefit_statuses/${id}?${serializeParams(params)}`),
    }
  },
  fitprofit_statuses:
      {
        admin: {
          show: (id, params) =>
              apiConnection.get(`admin/fitprofit_statuses/${id}?${serializeParams(params)}`),
        }
      },
  activity_templates: {
    admin: {
      index: (params) =>
        apiConnection.get(`admin/activity_templates?${serializeParams(params)}`),
      show: (id) =>
        apiConnection.get(`admin/activity_templates/${id}`),
      delete: (id) => apiConnection.delete(`admin/activity_templates/${id}`),
      create: (params) => apiConnection.post(`admin/activity_templates`, params),
      update: (id, params) => apiConnection.patch(`admin/activity_templates/${id}`, params),
    }
  },
  system_events: {
    admin: {
      add_comment: (id, params) =>
        apiConnection.post(`admin/system_events/${id}/add_comment`, params),
      index: (params) =>
        apiConnection.get(`admin/system_events?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/system_events`, params),
      update: (id, params) =>
        apiConnection.patch(`admin/system_events/${id}`, params),
      show: (id) => apiConnection.get(`admin/system_events/${id}`),
      delete: (id) => apiConnection.delete(`admin/system_events/${id}`),

    }
  },
  stats: {
    admin: {
      activities_per_day: (params) =>
        apiConnection.get(`admin/stats/activities_per_days?${serializeParams(params)}`),
      new_users: (params) =>
        apiConnection.get(`admin/stats/new_users?${serializeParams(params)}`),
      bought_activities: (params) =>
        apiConnection.get(`admin/stats/bought_activities?${serializeParams(params)}`),
    },

  },
  audits: {
    admin: {
      index: (params) =>
        apiConnection.get(`admin/audits?${serializeParams(params)}`),
    },
  },
  searchTags: {
    admin: {
      index: (params) =>
        apiConnection.get(`admin/search_tags?${serializeParams(params)}`),
    },
  },
  newsletter: {
    create: (params) => apiConnection.post(`newsletters`, params),
  },
  transactions: {
    currentUser: {
      basket: (params) =>
        apiConnection.get(
          `transactions/basket_transactions/1?${serializeParams(params)}`
        ),
      basketCreate: (params) =>
        apiConnection.post(
          `transactions/basket_transactions/?${serializeParams(params)}`
        ),
      index: (params) =>
        apiConnection.get(
          `transactions/current_user_transactions?${serializeParams(
            params
          )}`
        ),
      cancelUnpaidTransaction: (id) =>
        apiConnection.post(
          `transactions/cancel_unpaid_transaction/${id}?`
        ),
    },
    admin: {
      index: (params) =>
        apiConnection.get(
          `admin/transactions?${serializeParams(params)}`
        ),
    },
  },
  basket: {
    admin: {
      index: (user_id, params) =>
          apiConnection.get(`admin/users/${user_id}/basket_items?${serializeParams(params)}`),
      update: (user_id, id, params) =>
          apiConnection.patch(`admin/users/${user_id}/basket_items/${id}`, params),
      remove: (user_id, id) => apiConnection.delete(`admin/users/${user_id}/basket_items/${id}`),
    },
  },
  activities: {
    index: (params) =>
      apiConnection.get(`activities?${serializeParams(params)}`),
    show: (id, access_token) =>
      apiConnection.get(`activities/${id}?access_token=${access_token}`),
    resign: (id) => apiConnection.delete(`activities/${id}/resign`),
    check_list: (id) =>
      apiConnection.post(`activities/${id}/check_list`),
    delete: (id) => apiConnection.delete(`activities/${id}`),
    create: (params) => apiConnection.post(`activities`, params),
    update: (id, params) =>
      apiConnection.put(`activities/${id}`, params),
    reserveList: {
      create: (activity_id) =>
        apiConnection.post(
          `activities/${activity_id}/reserve_lists`,
          {}
        ),
      resign: (activity_id) =>
        apiConnection.delete(
          `activities/${activity_id}/reserve_lists/1`
        ),
    },
    coordinators: {
      create: (activity_id) =>
        apiConnection.post(
          `activities/${activity_id}/activity_coordinators`,
          {}
        ),
    },
    participants: {
      index: (id, params) =>
        apiConnection.get(
          `activities/${id}/activity_participants?${serializeParams(
            params
          )}`
        ),

      update: (activity_id, id, params) =>
        apiConnection.patch(
          `activities/${activity_id}/activity_participants/${id}`,
          params
        ),
    },
    admin: {
      team_line_ups: {
        index: (id, params) =>
            apiConnection.get(
                `admin/activities/${id}/activity_team_line_ups?${serializeParams(
                    params
                )}`
            ),
        delete: (id, line_up_id) =>
            apiConnection.delete(
                `admin/activities/${id}/activity_team_line_ups/${line_up_id}`
            ),
        create: (id, params) =>
            apiConnection.post(
                `admin/activities/${id}/activity_team_line_ups?`, params
            ),
      },
      edit_summary:  (id, params) =>
        apiConnection.patch(
            `admin/activities/${id}/edit_summary`,
            params
        ),
      participants: {
        send_list:  (id) =>
            apiConnection.get(
                `admin/activities/${id}/activity_participants/send_list`
            ),
        index: (id, params) =>
          apiConnection.get(
            `admin/activities/${id}/activity_participants?${serializeParams(
              params
            )}`
          ),
        add: (activity_id, id, team_id, add_costs = true) =>
          apiConnection.post(
            `admin/activities/${activity_id}/activity_participants/`, {activity_participant :{add_costs: add_costs, user_id: id, team_id: team_id}}
          ),
        update: (activity_id, id, params) =>
          apiConnection.patch(
            `admin/activities/${activity_id}/activity_participants/${id}`,
            params
          ),
        delete: (activity_id, id, add_costs = true) =>
          apiConnection.delete(
            `admin/activities/${activity_id}/activity_participants/${id}?add_costs=${add_costs}`
          ),
      },
      check_list: (id) =>
        apiConnection.post(`admin/activities/${id}/check_list`),
      index: (params) =>
        apiConnection.get(`admin/activities?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/activities`, params),
      update: (id, params) =>
        apiConnection.put(`admin/activities/${id}`, params),
      show: (id) => apiConnection.get(`admin/activities/${id}`),
      delete: (id) => apiConnection.delete(`admin/activities/${id}`),
      coordinators: {
        add: (id, params) =>
          apiConnection.post(
            `admin/activities/${id}/add_coordinator`,
            params
          ),
        remove: (id, params) =>
          apiConnection.post(
            `admin/activities/${id}/remove_coordinator`,
            params
          ),
      },
    },
  },
  cities: {
    index: (params) =>
      apiConnection.get(`cities?${serializeParams(params)}`),
    show: (id) => apiConnection.get(`cities/${id}`),
  },
  sports: {
    index: (params) =>
      apiConnection.get(`sports?${serializeParams(params)}`),
    show: (id) => apiConnection.get(`sports/${id}`),
    admin: {
      index: (params) =>
        apiConnection.get(`admin/sports?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/sports`, params),
      update: (id, params) =>
        apiConnection.patch(`admin/sports/${id}`, params),
      show: (id) => apiConnection.get(`admin/sports/${id}`),
      delete: (id) => apiConnection.delete(`admin/sports/${id}`),
    },
  },
  locations: {
    index: (params) =>
      apiConnection.get(`locations?${serializeParams(params)}`),
    show: (id) => apiConnection.get(`locations/${id}`),
    create: (params) => apiConnection.post(`locations`, params),
    update: (id, params) =>
      apiConnection.patch(`locations/${id}`, params),
    admin: {
      delete_image_attachment: (id, location_id) =>
        apiConnection.delete(
          `/admin/locations/${location_id}/delete_image_attachment/${id}`
        ),
      index: (params) =>
        apiConnection.get(`admin/locations?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/locations`, params),
      update: (id, params) =>
        apiConnection.patch(`admin/locations/${id}`, params),
      show: (id) => apiConnection.get(`admin/locations/${id}`),
      delete: (id) => apiConnection.delete(`admin/locations/${id}`),
    },
  },
  pages: {
    admin: {
      index: (params) =>
        apiConnection.get(`admin/pages?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/pages`, params),
      update: (id, params) =>
        apiConnection.patch(`admin/pages/${id}`, params),
      show: (id) => apiConnection.get(`admin/pages/${id}`),
      delete: (id) => apiConnection.delete(`admin/pages/${id}`),
    },

    show: (url) => apiConnection.get(`pages?url=${url}`),
  },
  settings: {
    admin: {
      index: (params) =>
          apiConnection.get(`admin/settings?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/settings`, params),
      update: (id, params) =>
          apiConnection.patch(`admin/settings/${id}`, params),
      show: (id) => apiConnection.get(`admin/settings/${id}`),
      delete: (id) => apiConnection.delete(`admin/settings/${id}`),
    },
  },
  reports: {
    admin: {
      index: (params) =>
          apiConnection.get(`admin/reports?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/reports`, params),
    },
  },
  benefit_entries: {
    admin: {
      index: (params) =>
          apiConnection.get(`admin/benefit_entries?${serializeParams(params)}`),
      update: (id, params) =>
          apiConnection.patch(`admin/benefit_entries/${id}`, params),
      create: (params) => apiConnection.post(`admin/benefit_entries`, params),
    },
  },
  mainPageTiles: {
    admin: {
      index: (params) =>
        apiConnection.get(`admin/main_page_tiles?${serializeParams(params)}`),
      create: (params) => apiConnection.post(`admin/main_page_tiles`, params),
      update: (id, params) =>
        apiConnection.patch(`admin/main_page_tiles/${id}`, params),
      show: (id) => apiConnection.get(`admin/main_page_tiles/${id}`),
      delete: (id) => apiConnection.delete(`admin/main_page_tiles/${id}`),
    },
  },
  promo_codes: {
    admin: {
      index: (params) =>
        apiConnection.get(
          `admin/promo_codes.json?${serializeParams(
            params
          )}`
        ),
      add: (params) =>
        apiConnection.post(
          `admin/promo_codes.json`, params
        ),
      update: (id, params) =>
        apiConnection.patch(
          `admin/promo_codes/${id}.json`,
          params
        ),
      show: (id) =>
        apiConnection.get(
          `admin/promo_codes/${id}.json`
        ),
      delete: (id) =>
        apiConnection.delete(
          `admin/promo_codes/${id}.json`
        ),
    }
  },
  users: {
    admin: {
      opinions: {
        index: (id, params) =>
          apiConnection.get(`admin/users/${id}/user_level_opinions?${serializeParams(params)}`),
      },
      roles: (params) =>
        apiConnection.get(
          `admin/roles?limit=1000${serializeParams(params)}`
        ),
      index: (params) =>
        apiConnection.get(`admin/users?${serializeParams(params)}`),
      show: (id) => apiConnection.get(`admin/users/${id}`),
      delete: (id) => apiConnection.delete(`admin/users/${id}`),
      update: (id, params) =>
        apiConnection.patch(`admin/users/${id}`, params),
      create: (params) => apiConnection.post(`admin/users`, params),
      balance: {
        add: (id, params) =>
          apiConnection.post(`admin/users/${id}/balance_add`, params),
        delete: (id, params) =>
          apiConnection.post(`admin/users/${id}/balance_delete`, params),
      },
      bonus: {
        add: (id, params) =>
          apiConnection.post(`admin/users/${id}/bonus_add`, params),
        delete: (id, params) =>
          apiConnection.post(`admin/users/${id}/bonus_delete`, params),
      },
      deposit: {
        add: (id, params) =>
          apiConnection.post(`admin/users/${id}/deposit_add`, params),
        delete: (id, params) =>
          apiConnection.post(`admin/users/${id}/deposit_delete`, params),
      },
    },
    index: (params) =>
      apiConnection.get(`users?${serializeParams(params)}`),
    login: (params) => loginApiConnection.post("users/sign_in.json", params),
    FBlogin: (params) => loginApiConnection.post("users/sign_in.json", { fb: true, ...params }),
    logout: () => loginApiConnection.delete("users/sign_out"),
    register: (params) => loginApiConnection.post("users", params),
    updatePassord: (params) => loginApiConnection.put("users/password.json", params),
    confirmAccount: (token) => loginApiConnection.get(`users/confirmation?confirmation_token=${token}`),
    show: (id) => apiConnection.get(`users/${id}`),
    getToken: () =>
      apiConnection.get(`users/confirm_phone_token_sender`),
    confirm_phone_token: (params) =>
      apiConnection.post(`users/confirm_phone_token`, params),
    update: (id, params) => apiConnection.patch(`users/${id}`, params),
    balanceAdd: (params) =>
      apiConnection.post(`transactions/balance_add`, params),
    transactions: (id) =>
      apiConnection.get(`transactions/${id}`),
    bonusValue: (value) =>
      apiConnection.post(`transactions/bonus_value?value=${value}`),
    depositToAdd: () => apiConnection.get(`transactions/deposit_to_add`),
    depositAdd: () => apiConnection.post(`transactions/deposit_add`),
    depositTransfer: () =>
      apiConnection.post(`transactions/deposit_transfer`),
    depositCheck: () => apiConnection.get(`transactions/deposit_ckeck`),
    depositReturn: () =>
      apiConnection.post(`transactions/deposit_return`),
    resetPassword: (email) =>
      apiConnection.get(`users/reset_password?email=${email}`),
    allowToRegister: {
      add: (params) =>
        apiConnection.post(`users/allowed_to_register_add`, params),
      remove: (params) =>
        apiConnection.post(`users/allowed_to_register_remove`, params),
    },
  },
};
