import React from "react";
import {Api} from "PNH/services/Api";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button";
import Slide from "@material-ui/core/Slide";
import {UsersFunctions} from "./index";
import AsyncSelector from "PNH/components/AsyncSelector/AsyncSelector";
import CloseIcon from "@material-ui/icons/Close";
import Primary from "components/Typography/Primary";

import avatar from "PNH/assets/img/placeholder.jpg";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "../../../../../../components/Table/Table";
import TablePagination from "@material-ui/core/TablePagination";

const styles = {
    ...formRegular,
    ...stylesExtended,
    ...notificationsModal,
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class Form extends React.Component {
    state = {
        deleteModal: false,
    };

    constructor(props) {
        super(props);
        this.fetch();
    }

    deleteModal = (open) => {
        this.setState({deleteModal: open});
    };

    fetch = () => {
        if (this.props.id) {
            Api.competition_groups.teams.admin.players(this.props.competition_group_id, this.props.team_id).then((response) => {
                if (response.ok) {
                    this.props.setValues({
                        ...UsersFunctions.defaultValues(this.props),
                        ...response.data,
                    });
                } else {
                    window.setNotifications([
                        {type: "alert", msg: "Coś poszło nie tak"},
                    ]);
                }
            });
        }
    };

    render() {
        const {
            classes,
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
        } = this.props;

        const genders = {females: "female", males: 'male'}

        const filters = ['females', 'males'].includes(values.gender) ? {
            with_competition_group_id: values.competition_group_id,
            with_gender: genders[values.gender]
        } : {with_competition_group_id: values.competition_group_id}

        const mapRows = (row) => {
            const {classes, formik} = this.props;

            return [
                <>
                    <Button size={"sm"} justIcon={true} color={"danger"} style={{marginRight: "30px"}}
                            onClick={async (e) => {
                                await setFieldValue("action", "remove");
                                await setFieldValue("team_user_id", row.id);
                                handleSubmit(e);
                            }}><CloseOutlinedIcon
                        fontSize={"small"}/></Button>{row.user.label}</>,

                <FormControl key={`ur-${row.id}-${row.role}`} fullWidth className={classes.selectFormControl}>
                    <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                    >
                        <span>Rola</span>
                    </InputLabel>
                    <Select
                        MenuProps={{
                            className: classes.selectMenu,
                        }}
                        classes={{
                            select: classes.select,
                        }}
                        value={row.role}
                        onChange={(event) => UsersFunctions.update(row.id, row.competition_group_id, this.props.team_id, event.target.value, this.props, () => this.fetch())}
                        inputProps={{
                            name: "role",
                        }}
                    >
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            value="capitan"
                        >
                            Kapitan
                        </MenuItem>
                        <MenuItem
                            classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                            }}
                            value="player"
                        >
                            Gracz
                        </MenuItem>
                    </Select>
                </FormControl>
            ];
        };

        return (
            <GridContainer>

                <GridItem xs={12}>
                    <GridContainer jusify="space-between">
                        <GridItem xs>
                            <AsyncSelector
                                apiMethod={(q) =>
                                    Api.users.admin.index({
                                        ...filters,
                                        ...q,
                                    })
                                }
                                labelKey="label"
                                label="Użytkownik"
                                name="user"
                                value={
                                    values.user && {
                                        id: values.user.id,
                                        label: values.user.label,
                                    }
                                }
                                onChange={(value, data) => {
                                    setFieldValue("user", data);
                                    setFieldValue("user_id", value);
                                }}
                            />
                        </GridItem>
                        <GridItem xs>
                            <Button
                                color="primary"
                                disabled={!values.user_id}
                                onClick={async (e) => {
                                    await setFieldValue("action", "add");
                                    handleSubmit(e);
                                }}
                            >
                                Dodaj
                            </Button>
                        </GridItem>

                        <GridItem xs={12}>
                            <GridContainer jusify="space-between">
                                {!values.rows[0] && (
                                    <GridItem xs={12}>
                                        <Primary>
                                            <h5>
                                                <b>Brak Osób</b>
                                            </h5>
                                        </Primary>
                                    </GridItem>
                                )}
                                {values.rows[0] && (
                                    <GridItem xs={12}>
                                        <Primary>
                                            <h5>
                                                <b>Lista osób</b>
                                            </h5>
                                        </Primary>
                                    </GridItem>
                                )}
                                <GridItem xs={12}>
                                    <Table
                                        striped
                                        tableHead={[
                                            <b>Gracz</b>,
                                            <b>Rola</b>,
                                        ]}
                                        tableData={values.rows.map(mapRows)}
                                    />
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </GridItem>
            </GridContainer>
        );
    }
}


export default withStyles(styles)(Form);
