import {withFormik} from "formik";
import validationSchema from "./ValidationSchema";
import ActivitiesTable from "PNH/components/Filters/Table"
import {Api, serializeParams} from "PNH/services/Api";
import {withRouter} from 'react-router-dom';
import {RowFunction} from "./RowFunction";
import {TableFilters} from "./Filters";
import moment from "moment";
import {FormController} from "../Form";

export const SearchFunctions = {
    index: (values, formik) => {

        return Api.benefit_entries.admin
            .index({...values, ...{data: [], search: true}})
            .then((response) => {
                if (response.ok) {

                    formik.setFieldValue("data", response.data);
                    formik.setFieldValue("total", response.data.total);

                    // eslint-disable-next-line
                    if(window.location.pathname === "benefit_entries") {
                        const params = {...values, ...{data: []}};
                        history.pushState({}, "benefit_entries", "/benefit_entries?" + serializeParams(params));
                    }

                    window.scrollTo(0, 0);
                }
            });
    },

    getData: async (formik) => {
        SearchFunctions.index(formik.values, formik);
    },

    changePage: async (page, formik) => {
        let pageChange = await formik.setFieldValue('page', page)
        SearchFunctions.index({...formik.values, ...{page: page}}, formik);
    },

    mapRows: (row, formik) => {
        return RowFunction(row, formik)
    },

    clearFilters: () => {
        window.location.href = window.location.pathname;
    },

    onChangeRowsPerPage: async (e, formik) => {
        let limit = await formik.setFieldValue('limit', e.target.value);
        let page = await formik.setFieldValue('page', 0)
        SearchFunctions.getData(formik)
    },

    handleAddButton: (formik) => {
        return null
    },

    bulkUpdateComponent: (formik) => {
        return null
    },

    filtersComponent: (formik) => {
        return TableFilters(formik)
    },

    tableHeaders: () => [
        "UUID",
        "ID Lokalizacji",
        "Nazwa Lokalizacji",
        "SN",
        "Terminal",
        "Data wpisu",
        "Nr Karty",
        "Koordynator login",
        "Zweryfikowano",
        "Przypisane zajęcia",
        "Przypisany Gracz",
        "Przypisany Koordynator",
        "Odrzucone",
        "Typ",
        "Notatki",
        "Zaimportowano"
    ],

    addButtonCustomComponent: (formik) => {
        return <FormController afterSave={() => SearchFunctions.getData(formik)} activity={formik.activity} activity_id={formik.activity_id} />
    },

    showAddButton: () => false,
    showClearButton: () => true,

    orderBy: () => [
        "benefit_entries.created_at asc",
        "benefit_entries.created_at desc",
    ],

    defaultValues: (props) => {
        const urlParams = new URLSearchParams(props.location.search);
        return {
            page: parseInt(urlParams.get("page")) || 0,
            limit: 25,
            data: {rows: []},
            total: 0,
            order: 'benefit_entries.created_at desc',
            with_uuid: urlParams.get("with_uuid") || "",
            with_created_at_greater: urlParams.get("with_created_at_greater") || "",
            with_created_at_less: urlParams.get("with_created_at_less") || "",
            with_date_played_greater: urlParams.get("with_date_played_greater") || "",
            with_date_played_less: urlParams.get("with_date_played_less") || "",
            with_tags: urlParams.get("with_tags") || "",
            with_facility_name: urlParams.get("with_facility_name") || "",
            with_organizer_login: urlParams.get("with_organizer_login") || "",
            with_confirmed: urlParams.get("with_confirmed") || "",
            with_activity_id: props.activity_id || "",
            with_user_id: props.userID || "",
            afterSave: props.afterSave,
        };
    },
};

export const FiltersForm = withRouter(withFormik({
    validationSchema: validationSchema,
    mapPropsToValues: (props) => {
        return {...SearchFunctions.defaultValues(props)};
    },
    handleSubmit: (values, formikProps) => {
        const tmp = {...values, ...{page: 0}}
        formikProps.setSubmitting(true);

        SearchFunctions.index(tmp, formikProps)
            .then((response) => {
            })
            .then(() => {
                formikProps.setFieldValue("page", 0);
                formikProps.setTouched({});
            });
    },
})(({...props}) => <ActivitiesTable SearchFunctions={SearchFunctions} {...props} />));
