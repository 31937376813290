import {withFormik} from "formik";
import validationSchema from "./ValidationSchema";
import Form from "./Form";
import {Api} from "PNH/services/Api";
import {currentUrlParams} from "PNH/services/Helpers";
import {ConnectToChannel, Disconnect, Send, Unsubscribe, WsManager} from "PNH/services/Ws";
import moment from "moment";
import {useEffect} from "react";

const CHANNEL_NAME = "Admin::ActivitiesChannel"

export const ActivitiesFunctions = {
    receivedMsg: (data) => {
        let type = 'alert';
        switch (data.code) {
            case 200:
                type = 'success';
                break;
            case 202:
                type = 'warning';
                break;
            default:
                type = 'alert';
        }

        window.setNotifications([
            {type: type, msg: data.status},
        ]);
    },

    connectedToChannel: (data) => {
        console.log(data)
    },

    disconnectedToChannel: (data) => {
        console.log(data)
    },


    create: (values, formik) => {
        values.afterSave();
        formik.setFieldValue("afterSave", () => {
        });
        formik.setTouched({});
        ActivitiesFunctions.ws.send({params: {...values, action: 'create'}})
        // Send(CHANNEL_NAME, {params: {...values, action: 'create'}})
        setTimeout(() => formik.setSubmitting(false), 3000);
    },
    update: (values, formik) => {
        values.afterSave();
        formik.setFieldValue("afterSave", () => {
        });
        formik.setTouched({});
        ActivitiesFunctions.ws.send({params: {...values, action: 'update'}})
        // Send(CHANNEL_NAME, {params: {...values, action: 'update'}})
        setTimeout(() => formik.setSubmitting(false), 3000);
    },
    delete: (formik) => {
        return Api.activities.admin.delete(formik.values.id).then((response) => {
            if (response.ok) {
                window.setNotifications([
                    {type: "success", msg: "Usunięto"},
                ]);

                let url = `/activities`;
                formik.history.push(url)
            } else {
                window.setNotifications([
                    {type: "alert", msg: "Nie udało się usunąć"},
                ]);
            }
        });
    },


    defaultValues: (props) => {
        const defaultValues = {
            id:
                currentUrlParams().urlPath[1] && currentUrlParams().urlPath[1] !== "new" && !props.copy
                    ? currentUrlParams().urlPath[1]
                    : "",
            localization_id: props.location_id || "",
            location_object: "",
            sport_id: "",
            name: "",
            description: "",
            hidden: "",
            price: "",
            notes: "",
            date: "",
            activity_type: "pnh",
            publish_date: moment().format("YYYY-MM-DD H:m"),
            lv: "",
            gender: "",
            localizations: "",
            reserved_spots: 0,
            max_players: 0,
            min_players: 0,
            creator_id: JSON.parse(localStorage.getItem('user')).id,
            sports: [],
            start_at: "",
            stop_at: "",
            baner_image: "",
            thumb_image: "",
            custom_tags: "",
            supplement: 0,
            supplement_medicover: 0,
            ok_system_code: "",
            change_to_public_at: "",
            event_type: "",
            discount: "",
            rental_price: "",
            max_price: "",
            max_benefit_price: "",
            page_id: "",
            verify_lv: false,
            competition_type: 'activity',
            max_team_size: 2,
            min_team_size: 1,
            min_age: 18,
            max_age: 100,
            pay_by_bonus_account: true,
            date_from: moment().format("YYYY-MM-DD"),
            date_to: moment().format("YYYY-MM-DD"),
            days: [],
            competition_groups: [],
            competition_teams: [],

            afterSave: () => {
            },
        };
        return {...defaultValues, ...props.defaults};
    },
};


const FormWrapper = ({...props}) => {
    useEffect(() => {
        const ws = new WsManager()
        ws.connect(CHANNEL_NAME, {
            connected: ActivitiesFunctions.connectedToChannel,
            disconnected: ActivitiesFunctions.disconnectedToChannel,
            received: ActivitiesFunctions.receivedMsg
        })
        ActivitiesFunctions.ws  = ws

        return function disconnect() {
            ws.unsubscribe()
            ws.disconnect()
        }
    }, []);

    return <Form {...props}/>
}

export const ActivitiesForm = withFormik({
    validationSchema: validationSchema,
    mapPropsToValues: (props) => {
        return {...ActivitiesFunctions.defaultValues(props)};
    },
    handleSubmit: (values, formikProps) => {
        formikProps.setSubmitting(true);
        if (!values.id || formikProps.props.copy) {
            ActivitiesFunctions.create(values, formikProps)
        } else {
            ActivitiesFunctions.update(values, formikProps)
        }
    },
})(FormWrapper);
