/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "PNH/components/Navbars/AdminNavbar";
import Footer from "PNH/components/Footer/Footer";
import Sidebar from "PNH/components/Sidebar/Sidebar";

import routes from "routes.js";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import logo from "PNH/assets/img/logo.png"
var ps;

const sidebarColors = {
  "localhost:3002": 'blue',
  "admin.pnh.selfapps.pl": 'black'
}
const sidebarTitle = {
  "localhost:3002": 'dev',
  "admin.pnh.selfapps.pl": 'TEST'
}

class Dashboard extends React.Component {
  state = {
    mobileOpen: false,
    miniActive: true,
    image: require("assets/img/sidebar-2.jpg"),
    color: "white",
    bgColor: sidebarColors[window.location.host] || 'white',
    hasImage: false,
    fixedClasses: "dropdown",
    logo: logo,
  };
  mainPanel = React.createRef();
  componentDidMount() {
    console.log(window.location.host)
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  // componentDidUpdate(e,p) {
  //   console.log('update')
  //   console.log(e,p)
  //   console.log(this.state, this.props)
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.mainPanel.current.scrollTop = 0;
  //     if (this.state.mobileOpen) {
  //       if(this.state.mobileOpen){
  //         this.setState({ mobileOpen: false }, () => console.log(3));
  //       }
  //     }
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    Object.entries(this.props).forEach(([key, val]) =>
      prevProps[key] !== val && console.log(`Prop '${key}' changed`)
    );
    if (this.state) {
      Object.entries(this.state).forEach(([key, val]) =>
        prevState[key] !== val && console.log(`State '${key}' changed`)
      );
    }
  }

  handleImageClick = (image) => {
    this.setState({ image: image }, () => console.log(4));
  };
  handleColorClick = (color) => {
    this.setState({ color: color }, () => console.log(5));
  };
  handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        this.setState({ logo: require("PNH/assets/img/logo.png") }, () => console.log(6));
        break;
      default:
        this.setState({ logo: require("PNH/assets/img/logo.png") }, () => console.log(7));
        break;
    }
    this.setState({ bgColor: bgColor }, () => console.log(8));
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" }, () => console.log(9));
    } else {
      this.setState({ fixedClasses: "dropdown" }, () => console.log(10));
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen }, () => console.log(11));
  };
  getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  getActiveRoute = (routes) => {
    let activeRoute = "PNHAdmin";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return (
          <Route
            key={key + window.location.href}
            path={prop.layout + prop.path}
            component={() => prop.component(this.props)}
          />
        );
      } else {
        return null;
      }
    });
  };
  sidebarMinimize = () => {
    this.setState({ miniActive: !this.state.miniActive }, () => console.log(1));
  };
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      if(this.state.mobileOpen) {
        this.setState({ mobileOpen: false }, () => console.log(2));
      }
    }
  };
  render() {
    const { classes, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={ sidebarTitle[window.location.host] || "PNHAdmin"}
          logo={this.state.logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <div className={mainPanel} ref={this.mainPanel}>
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </div>
          </div>

          <Footer fluid />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(appStyle)(Dashboard);

