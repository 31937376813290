import React from "react";

import {PlayersForm} from "./Form/Players";
import CustomTabs from "components/CustomTabs/CustomTabs";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";


export const TeamsTab = ({activityID, activity, participants}) => {
    return <CustomTabs
        headerColor="primary"
        tabs={participants.map(p => {
            return  {
                tabName: p.team.name,
                tabIcon: SportsBasketballIcon,
                tabContent: <PlayersForm activity_id={activityID} activity={activity} team_id={p.team.id}/>
            }
        })}/>

}