import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom'
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import TablePagination from "@material-ui/core/TablePagination";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle";

import formRegular from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle";
import notificationsModal from "assets/jss/material-dashboard-pro-react/views/notificationsStyle";
import { I18n } from "../I18n";

const styles = {
  ...formRegular,
  ...stylesExtended,
  ...notificationsModal,
  ...extendedTablesStyle
};

const ActivitiesTable = (props) => {
  useEffect(() => {
    props.SearchFunctions.getData(props)
  }, [])

  const { classes, filters, values, SearchFunctions } = props;

  return (
    <>
      {SearchFunctions.addButtonCustomComponent && SearchFunctions?.addButtonCustomComponent(props)}
      {SearchFunctions.showAddButton() && <GridItem xs={12}>
        <Button
          style={{ float: "right" }}
          color="success"

          onClick={() => SearchFunctions.handleAddButton(props)}
        >
          Dodaj
        </Button>
      </GridItem>}
      {!filters && SearchFunctions.filtersComponent(props)}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="secondary">
              <h4 className={classes.cardIconTitle}>{I18n.t(values.data.model)}</h4>
            </CardHeader>
            <CardBody>
              <Table
                striped
                tableHead={SearchFunctions.tableHeaders()}
                tableData={values.data.rows.map((row) => SearchFunctions.mapRows(row, props))}
              />
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                page={values.page}
                rowsPerPage={values.limit}
                count={values.total}
                onChangePage={(e, page) => { SearchFunctions.changePage(page, props) }}
                onChangeRowsPerPage={(e) => { SearchFunctions.onChangeRowsPerPage(e, props) }}
                labelRowsPerPage="Ilość na stronie"
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} z ${count}`
                }
              />
              {SearchFunctions.bulkUpdateComponent(props)}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}


export default withRouter(withStyles(styles)(ActivitiesTable));
